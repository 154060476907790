import { ApiInstance } from '@/api/ApiInstance';
import { configuration } from '@/config';
import { Token } from '@/modules/session/domain/Types';
import { AuthorizationHeaderBuilder } from '@/api/AuthorizationHeaderBuilder';
// @ts-ignore
import { SaveCreativeEvent } from '../components/CreativeForm.vue';
import { Campaign } from '@/modules/campaign/domain/Campaign';
import { CreativePagedResponse } from '../rest/response/CreativePagedResponse';
import { CreativeListingData } from '../domain/CreativeListingData';
import { Id } from '@/modules/user/domain/Types';
import { Creative } from '../domain/Creative';

export class CreativeService {
    private static readonly api = ApiInstance.createFor(`${configuration.GATEWAY_URL}/pubs-neo/creative`);

    public static async getBy(id: Id, token: Token) {
        const { data: response } = await this.api
            .get<Creative>(`/${id}`, {
                headers: AuthorizationHeaderBuilder.buildBearerWith(token),
            });

        return response;
    }

    public static async restore(creative: CreativeListingData, token: Token) {
        await this.api.put(`/${creative.id}/restore`, {}, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }

    public static async delete(creative: CreativeListingData, token: Token) {
        await this.api.put(`/${creative.id}/delete`, {}, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }

    public static async getCreativesFor(campaign: Campaign, token: Token) {
        const { data: response } = await this.api
            .get<CreativePagedResponse>(`/?search=campaign:${campaign.id}`, {
                headers: AuthorizationHeaderBuilder.buildBearerWith(token),
            });

        if (!response._embedded) {
            return [];
        }

        return response._embedded.creatives;
    }

    public static async update(creative: SaveCreativeEvent, token: Token) {
        await this.api.put('/', creative, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }

    public static async save(creative: SaveCreativeEvent, token: Token) {
        await this.api.post('/', creative, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }
}
