import { ApiInstance } from '@/api/ApiInstance';
import { Token } from '@/modules/session/domain/Types';
import { Category } from '../domain/Category';
import { AuthorizationHeaderBuilder } from '@/api/AuthorizationHeaderBuilder';
import { configuration } from '@/config';
import { CategorySearchCriteria } from '../domain/CategorySearchCriteria';
import { CategoryListingResponse } from '../rest/response/CategoryListingResponse';
import { CategorySubmitEvent } from '@/modules/category/components/CategoryForm.vue';

export class CategoryService {
    private static readonly api = ApiInstance.createFor(`${configuration.GATEWAY_URL}/pubs-neo/category`);

    public static async getAll(token: Token) {
        const { data: categories } = await this.api.get<Category[]>('/all', {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });

        return categories;
    }

    public static async getById(id: string, token: Token) {
        const { data: category } = await this.api.get<Category>('/' + id, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });

        return category;
    }

    public static async getCategoryFor(searchCriteria: CategorySearchCriteria, token: Token) {
        const nameCriteria = searchCriteria.name ? `name:${searchCriteria.name}` : '';
        const { data: response } = await this.api
            .get<CategoryListingResponse>(`/?search=${nameCriteria}&page=${searchCriteria.page}`, {
                headers: AuthorizationHeaderBuilder.buildBearerWith(token),
            });

        if (!response._embedded) {
            return { categories: [], pageCount: 1};
        }

        return { categories: response._embedded.categories, pageCount: response.page.totalPages };
    }

    public static async update(id: string, category: CategorySubmitEvent, token: Token) {
        await this.api.put('/' + id, category, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }

    public static async insert(category: CategorySubmitEvent, token: Token) {
        await this.api.post('/', category, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }
}
