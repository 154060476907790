

















import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Category } from '../domain/Category';
import { Id } from '../../user/domain/Types';
import { CategoryService } from '../services/CategoryService';

@Component
export default class CategoryDropdown extends Vue {
    @Prop() private readonly givenCreativeCategoryId!: Id;

    private isLoading: boolean = false;
    private categories: Category[] = [];
    private selectedCategoryId: Id = '';

    private async mounted() {
        this.isLoading = true;
        try {
            this.categories = await CategoryService.getAll(this.$store.getters['sessionStore/token']);
        } catch {
            this.$notify.error({
                title: 'Erreur',
                message: 'Erreur lors du chargement de vos catégories. Veuillez réessayer.',
            });
        } finally {
            if (this.givenCreativeCategoryId) {
                this.selectedCategoryId = this.givenCreativeCategoryId;
            }
            this.updateCategory();
        }
        this.isLoading = false;
    }

    private updateCategory() {
        const category = this.categories.find((c) => c.id === this.selectedCategoryId);
        this.$emit('update', category);
    }

    @Watch('givenCreativeCategoryId')
    private onParentGivenCategoryIdUpdate() {
        if (this.givenCreativeCategoryId) {
            this.selectedCategoryId = this.givenCreativeCategoryId;
        }
    }

    @Watch('selectedCategoryId')
    private onSelectedCategoryIdUpdate() {
        this.updateCategory();
    }
}
