




















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Field from '@/components/Field.vue';
import { PortalListingData } from '../domain/PortalListingData';
import { PortalService } from '../services/PortalService';

@Component({
    components: { Field },
})
export default class PortalDropdown extends Vue {
    @Prop() private givenPortalId!: string;
    @Prop() private hasSubmitted!: boolean;
    @Prop() private editMode!: boolean;

    private isLoading: boolean = false;
    private portals: PortalListingData[] = [];
    private portalId: string = '';

    private async mounted() {
        await this.fetchPortals();
    }

    private async fetchPortals() {
        this.isLoading = true;
        try {
            this.portals = await PortalService.getAll(this.$store.getters['sessionStore/token']);
        } catch {
            this.$notify.error({
                title: 'Erreur',
                message: 'Erreur lors du chargement de vos portails. Veuillez réessayer',
            });
        }
        this.isLoading = false;
    }

    private formatPortalNameFor(portal: PortalListingData) {
        return `${portal.name} [${portal.target}]`;
    }

    @Watch('givenPortalId')
    private async onGivenPortalUpdate() {
        if (this.givenPortalId !== undefined) {
            if (this.portals.length === 0) {
                await this.fetchPortals();
            }
            this.portalId = this.givenPortalId;
        }
    }

    @Watch('portalId' , { deep: true })
    private async onPortalUpdate() {
        const portal = this.portals.find((p) => p.id === this.portalId);
        this.$emit('select', portal);
    }
}
