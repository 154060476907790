




















































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import Field from '@/components/Field.vue';
import { Category } from '../../category/domain/Category';
import CategoryDropdown from '@/modules/category/components/CategoryDropdown.vue';
import { configuration } from '../../../config';
import { AuthorizationHeaderBuilder } from '../../../api/AuthorizationHeaderBuilder';
import { UploadedMedia } from '../domain/UploadedMedia';
import { MediaType } from '../domain/MediaType';
import { Time } from '../../../utils/Time';
import { Creative } from '../domain/Creative';
import { CreativeState } from '@/modules/creative/domain/CreativeState';
import PortalZoneTransfer from '@/modules/portal/components/PortalZoneTransfer.vue';
import { PortalZone } from '@/modules/portal/domain/PortalZone';
import { PortalService } from '@/modules/portal/services/PortalService';
import { Portal } from '@/modules/portal/domain/Portal';
import PortalDropdown from '@/modules/portal/components/PortalDropdown.vue';
import { PortalTarget } from '@/modules/portal/domain/PortalTarget';

@Component({
    components: { Field, CategoryDropdown, PortalZoneTransfer, PortalDropdown },
})
export default class CreativeForm extends Vue {
    @Prop({default: {id: '',
            name: '',
            caption: '',
            startDate: '',
            endDate: '',
            state: CreativeState.INACTIVE,
            categoryId: undefined,
            campaignId: undefined,
            media: '',
            type: '',
            duration: '',
            portalId: undefined,
            zones: []} }) private readonly creative!: Creative;
    @Prop() private editMode!: boolean;
    public rules =  {
        name: [
            { required: true, message: 'Ce champs est requis!', trigger: 'blur' },
        ],
        displayArea: [
            { required: true, message: 'Ce champs est requis!', trigger: 'blur' },
        ],
        categoryId : [
            { required: true, message: 'Ce champs est requis!', trigger: 'blur' },
        ],
        media : [
            { required: true, message: 'Ce champs est requis!', trigger: 'blur' },
        ],
        startDate : [
            { required: true, message: 'Ce champs est requis!', trigger: 'blur' },
        ],
        endDate : [
            { required: true, message: 'Ce champs est requis!', trigger: 'blur' },
        ],
        zones : [
            { required: true, message: 'Ce champs est requis!', trigger: 'blur' },
        ],
        portalId : [
            { required: true, message: 'Ce champs est requis!', trigger: 'blur' },
        ],
    };

    private isLoading: boolean = false;
    private hasSubmitted: boolean = false;
    private readonly acceptedFileType = [ 'image/jpeg', 'image/jpg', 'image/png', 'video/mp4', 'image/gif' ];
    private category: Category | null = null;
    private uploadedMedia: { duration: number | undefined; fileName: string; type: string } | null = null;
    private imageDuration: number = 30;
    private portalZones: PortalZone[] | PortalZone[] = [];
    private givenSelectedZones: PortalZone[] = [];
    private isLoadingZones: boolean = false;

    private async submit() {
        this.hasSubmitted = true;
        const form: any = this.$refs.creativeForm;
        form.validate((valid: boolean) => {
            if (valid) {
                this.$emit('saveCreative', this.creative);
            }
        });
    }

    private onFileUploadSuccess(uploadedMedia: UploadedMedia, file: File) {
        this.uploadedMedia = uploadedMedia;
        this.creative.media = uploadedMedia.fileName;
        this.creative.type = uploadedMedia.type;
        this.creative.duration = uploadedMedia.duration;

        if (this.mediaIsImage) {
            this.creative.duration = Time.ofSeconds(this.imageDuration).asMilliseconds();
        }

        this.isLoading = false;
    }

    private onFileUploadError() {
        this.isLoading = false;
        this.$message.error('L\'upload est impossible! Vérifier la grosseur du fichier');
    }

    private beforeVideoUpload(file: File) {
        if (!this.acceptedFileType.some((type) => file.type === type)) {
            return false;
        }
        this.isLoading =  true;
        return true;
    }

    private onFileRemove() {
        this.uploadedMedia = {
            duration: undefined,
            fileName: '',
            type: MediaType.IMG,
        };
        this.imageDuration = 30;
        this.creative.media = '';
        this.creative.type = MediaType.IMG;
        this.creative.duration = 30000;
    }

    private onFileLimitExceeded() {
        this.$message.error('Vous ne pouvez pas mettre plus d\'un média par créatif');
    }

    private onSelectedCategoryUpdate(category: Category) {
        this.category = category;
        if (this.category !== undefined) {
            if (typeof this.category.id === 'string') {
                this.creative.categoryId = this.category.id;
            }
        } else {
            this.creative.categoryId = '';
        }
    }


    @Watch('imageDuration')
    private onImageDurationUpdate() {
        if (!this.uploadedMedia) {
            return;
        }

        this.creative.duration = Time.ofSeconds(this.imageDuration).asMilliseconds();
        return this.uploadedMedia.duration = Time.ofSeconds(this.imageDuration).asMilliseconds();
    }

    @Watch('creative', { deep: true })
    private onParentGivenCreativeUpdate() {
        if (this.creative.id !== '') {
            this.uploadedMedia = {
                duration: this.creative.duration,
                type: this.creative.type,
                fileName: this.creative.media,
            };

            if (this.creative.type === MediaType.IMG) {
                this.imageDuration = Time.ofMilliseconds(this.creative.duration).asSeconds();
            }
        }

        this.givenSelectedZones = this.creative.zones;
    }

    private onZonesUpdate(zones: PortalZone[]) {
        this.setZonesAs(zones);
    }

    private setZonesAs(zones: PortalZone[]) {
        this.givenSelectedZones = zones;
        this.creative.zones = zones;
    }

    private async onPortalSelected(portal: Portal) {
        if (portal) {
            this.creative.portalId = portal.id;
            const zones = await PortalService.getZonesForUser(this.$store.getters['sessionStore/token']);
            this.portalZones = zones.filter((z: PortalZone) => z.portalId === portal.id);
            if (portal.target === PortalTarget.NEOTV) {
                // @ts-ignore
                this.$refs.url.$el.classList.add('hidden');
                // @ts-ignore
                this.$refs.title.$el.classList.add('hidden');
                // @ts-ignore
                this.$refs.description.$el.classList.add('hidden');
            } else {
                // @ts-ignore
                this.$refs.url.$el.classList.remove('hidden');
                // @ts-ignore
                this.$refs.title.$el.classList.remove('hidden');
                // @ts-ignore
                this.$refs.description.$el.classList.remove('hidden');
            }
        } else {
            this.creative.portalId = undefined;
            this.portalZones = [];
            // @ts-ignore
            this.$refs.url.$el.classList.add('hidden');
            // @ts-ignore
            this.$refs.title.$el.classList.add('hidden');
            // @ts-ignore
            this.$refs.description.$el.classList.add('hidden');
        }
    }

    get submitText() {
        if (this.creative.id !== undefined) {
            return 'Modifier le créatif';
        }

        return 'Sauvegarder le créatif';
    }

    get submitIcon() {
        if (this.creative.id !== undefined) {
            return 'el-icon-edit';
        }

        return 'el-icon-plus';
    }

    get submitType() {
        if (this.creative.id !== undefined) {
            return 'warning';
        }

        return 'success';
    }

    get mediaSource() {
        if (!this.uploadedMedia) {
            return '';
        }

        return `${configuration.MEDIA_URL}/${this.uploadedMedia.fileName}`;
    }

    get mediaIsImage() {
        return this.uploadedMedia !== null && this.uploadedMedia.type === MediaType.IMG;
    }

    get mediaIsVideo() {
        return this.uploadedMedia !== null && this.uploadedMedia.type === MediaType.VID;
    }

    get imageUploadLink() {
        return `${configuration.GATEWAY_URL}/pubs-neo/creative/upload`;
    }

    get uploadRequestHeaders() {
        return AuthorizationHeaderBuilder.buildBearerWith(this.$store.getters['sessionStore/token']);
    }
}
